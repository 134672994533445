/* Generated by https://google-webfonts-helper.herokuapp.com/fonts/ibm-plex-sans?subsets=latin 2021-12-23*/

/* ibm-plex-sans-100 - latin */
@font-face {
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: url('./fonts/ibm-plex-sans-v9-latin-100.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('./fonts/ibm-plex-sans-v9-latin-100.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/ibm-plex-sans-v9-latin-100.woff2')
			format('woff2'),
		/* Super Modern Browsers */ url('./fonts/ibm-plex-sans-v9-latin-100.woff')
			format('woff'),
		/* Modern Browsers */ url('./fonts/ibm-plex-sans-v9-latin-100.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('./fonts/ibm-plex-sans-v9-latin-100.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}
/* ibm-plex-sans-200 - latin */
@font-face {
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url('./fonts/ibm-plex-sans-v9-latin-200.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('./fonts/ibm-plex-sans-v9-latin-200.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/ibm-plex-sans-v9-latin-200.woff2')
			format('woff2'),
		/* Super Modern Browsers */ url('./fonts/ibm-plex-sans-v9-latin-200.woff')
			format('woff'),
		/* Modern Browsers */ url('./fonts/ibm-plex-sans-v9-latin-200.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('./fonts/ibm-plex-sans-v9-latin-200.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}
/* ibm-plex-sans-300 - latin */
@font-face {
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url('./fonts/ibm-plex-sans-v9-latin-300.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('./fonts/ibm-plex-sans-v9-latin-300.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/ibm-plex-sans-v9-latin-300.woff2')
			format('woff2'),
		/* Super Modern Browsers */ url('./fonts/ibm-plex-sans-v9-latin-300.woff')
			format('woff'),
		/* Modern Browsers */ url('./fonts/ibm-plex-sans-v9-latin-300.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('./fonts/ibm-plex-sans-v9-latin-300.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}
/* ibm-plex-sans-regular - latin */
@font-face {
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('./fonts/ibm-plex-sans-v9-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('./fonts/ibm-plex-sans-v9-latin-regular.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/ibm-plex-sans-v9-latin-regular.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('./fonts/ibm-plex-sans-v9-latin-regular.woff') format('woff'),
		/* Modern Browsers */ url('./fonts/ibm-plex-sans-v9-latin-regular.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('./fonts/ibm-plex-sans-v9-latin-regular.svg#IBMPlexSans')
			format('svg'); /* Legacy iOS */
}
/* ibm-plex-sans-500 - latin */
@font-face {
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url('./fonts/ibm-plex-sans-v9-latin-500.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('./fonts/ibm-plex-sans-v9-latin-500.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/ibm-plex-sans-v9-latin-500.woff2')
			format('woff2'),
		/* Super Modern Browsers */ url('./fonts/ibm-plex-sans-v9-latin-500.woff')
			format('woff'),
		/* Modern Browsers */ url('./fonts/ibm-plex-sans-v9-latin-500.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('./fonts/ibm-plex-sans-v9-latin-500.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}
/* ibm-plex-sans-600 - latin */
@font-face {
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url('./fonts/ibm-plex-sans-v9-latin-600.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('./fonts/ibm-plex-sans-v9-latin-600.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/ibm-plex-sans-v9-latin-600.woff2')
			format('woff2'),
		/* Super Modern Browsers */ url('./fonts/ibm-plex-sans-v9-latin-600.woff')
			format('woff'),
		/* Modern Browsers */ url('./fonts/ibm-plex-sans-v9-latin-600.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('./fonts/ibm-plex-sans-v9-latin-600.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}
/* ibm-plex-sans-700 - latin */
@font-face {
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('./fonts/ibm-plex-sans-v9-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('./fonts/ibm-plex-sans-v9-latin-700.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/ibm-plex-sans-v9-latin-700.woff2')
			format('woff2'),
		/* Super Modern Browsers */ url('./fonts/ibm-plex-sans-v9-latin-700.woff')
			format('woff'),
		/* Modern Browsers */ url('./fonts/ibm-plex-sans-v9-latin-700.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('./fonts/ibm-plex-sans-v9-latin-700.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

#kundo_chat_frame {
	right: 8px !important;
}
